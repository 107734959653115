import React from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import logo01 from "../assets/logos/logo01.png";
import logo02 from "../assets/logos/logo02.png";
import logo03 from "../assets/logos/logo03.png";
import logo04 from "../assets/logos/logo04.png";
import logo05 from "../assets/logos/logo05.png";
import logo06 from "../assets/logos/logo06.png";
import { Box } from "@pankod/refine-mui";

SwiperCore.use([Navigation, Pagination, Autoplay]);
const images = [
  logo01,
  logo02,
  logo03,
  logo04,
  logo05,
  logo06,
  logo01,
  logo02,
  logo03,
  logo04,
  logo05,
  logo06,
  logo02,
  logo03,
  logo04,
  logo05,
  logo06,
];
export const ImageSwiper = () => {
  return (
    <Box
      sx={{
        height: 200,
        width: 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        objectFit: "contain",
      }}
    >
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        // navigation
        // pagination={{ clickable: true }}
        autoplay={{ delay: 1000 }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
